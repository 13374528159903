import { createApp } from 'vue'
import './style.css'
import './animations.css'
import App from './App.vue'
import router from './router/router'
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle"
import { createI18n } from 'vue-i18n'
import messages from './utils/i18n/translations'
import { createPinia } from 'pinia'
import 'aos/dist/aos.css'

const language = localStorage.getItem('language') || 'en'

const i18n = createI18n({
  locale: language,
  allowComposition: true,
  messages,
})

createApp(App)
  .use(router)
  .use(i18n)
  .use(createPinia())
  .mount('#app')
