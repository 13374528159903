<template>
  <div
    id="carouselExampleControls"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="@/assets/images/for-websites-banner/2.jpg" class="banner d-block w-100" alt="..." />
      </div>
      <div class="carousel-item">
        <img src="@/assets/images/for-websites-banner/3.jpg" class="banner d-block w-100" alt="..." />
      </div>
      <div class="carousel-item">
        <img src="@/assets/images/for-websites-banner/4.jpg" class="banner d-block w-100" alt="..." />
      </div>
      <div class="carousel-item">
        <img src="@/assets/images/for-websites-banner/5.jpg" class="banner d-block w-100" alt="..." />
      </div>
      <div class="carousel-item">
        <img src="@/assets/images/for-websites-banner/6.jpg" class="banner d-block w-100" alt="..." />
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<style scoped>
.carousel-item .banner {
  height: 80vh;
  object-fit: fill;
}

@media screen and (max-width: 768px) {
  .carousel-item .banner {
    height: 75vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 425px) {
  .carousel-item .banner {
    height: 30vh;
  }
}
</style>
