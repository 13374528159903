<template>
  <div v-if="routeName === 'home'">
    <Carousel />
  </div>
  <div
    v-else
    class="banner"
    :style="{ backgroundImage: `url(${banners[routeName]})` }"
  >
    <div class="d-flex justify-content-start align-items-center h-100 px-3">
      <div data-aos="fade-right" class="text-md-center px-md-5">
        <!--
        <div class="d-block d-sm-block d-md-block d-lg-none" v-if="routeName === 'home'">
          <img src="@/assets/icons/animal-welfare-goat-big.svg" alt="">
        </div>
      -->
        <div class="bg-title">
          <h1
            id="banner-text"
            v-html="$t(bannerText[routeName])"
            class="font-montserrat-sb text-white font-weight-bold m-0"
          ></h1>
        </div>

        <!--
        <div v-if="routeName === 'home'" class="green">
          <h1 class="m-0">199$ {{ $t('price-banner') }}</h1>
        </div>

        <ButtonHover
          class="mt-4 mb-5 mx-auto d-block d-sm-block d-md-block d-lg-none"
          backgroundColor="#9a7a2a"
          :btn-text="$t('button-get-certified')"
        />
        -->
      </div>
      <!--
      <div class="d-none d-sm-none d-md-none d-lg-block" data-aos="zoom-out-left" v-if="routeName === 'home'">
        <img src="@/assets/icons/animal-welfare-goat-big.svg" alt="">
      </div>
      -->
    </div>
  </div>
</template>

<script>

import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { computed } from "vue";
import bannerHome from "@/assets/images/banner-home.jpg";
import bannerAbout from "@/assets/images/banner-about.jpg";
import bannerServices from "@/assets/images/banner-services.jpg";
import bannerTraining from "@/assets/images/banner-training.jpg";
import bannerContact from "@/assets/images/banner-contact-oveja.jpg";
import bannerPricing from "@/assets/images/banner-pricing.jpg";
import Carousel from "./Carousel.vue";
//import ButtonHover from './ButtonHover.vue'

export default {
  components: {
    //Header,
    Carousel
    //ButtonHover
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const routeName = computed(() => route.name);

    const bannerText = {
      home: "banner-home-text",
      about: "banner-text-about",
      contact: "banner-text-contact",
      "find-us": "banner-text-find-us",
      training: "banner-text-training",
      services: "banner-text-services",
      pricing: "banner-text-pricing",
    };

    const banners = {
      home: bannerHome,
      about: bannerAbout,
      contact: bannerContact,
      "find-us": bannerContact,
      training: bannerTraining,
      services: bannerServices,
      pricing: bannerPricing,
    };

    return {
      t,
      routeName,
      bannerText,
      banners,
    };
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 80vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banner-text {
  font-family: var(--montserrat-bold);
  color: white;
  /*background: rgb(29, 59, 31, 0.4);*/
  height: 100%;
}

.banner-text img {
  width: 15em;
}

.bg-title {
  background: rgb(29, 59, 31, 0.7);
  padding: 5px;
  border-radius: 3px;
}

.green {
  background: var(--secondary-color);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .banner {
    height: 75vh;
  }
  .banner-text img {
    width: 7em;
  }
}

@media screen and (min-width: 375px) and (max-width: 425px) {
  .banner {
    height: 30vh;
    background-size: 100% 100%;
  }
}
</style>
