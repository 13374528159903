<template>
    <h3 class="font-montserrat-sb text-center">{{ $t('footer.contact')}}</h3>
    <div class="contact-item">
        <img src="../../assets/icons/sms.svg" alt="">
        <p class="m-0">+1-816-646-4005</p>
    </div>
    <div class="contact-item">
        <img src="../../assets/icons/mail.svg" alt="">
        <p class="m-0">info@clstrategies.com</p>
    </div>
    <div class="contact-item">
        <img src="../../assets/icons/home.svg" alt="">
        <p class="direction m-0">1501 Jules St. <br>  Saint Joseph, Missouri 64502</p>
    </div>
</template>

<style scoped>
.contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    font-size: 17px;
}

.contact-item img {
    width: 35px;
}
</style>
