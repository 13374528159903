<template>
  <button v-if="!href" class="button font-montserrat-m">{{ btnText }}</button>
  <a v-else :href="href" target="_blank" class="button font-montserrat-m">{{ btnText }}</a>
</template>

<script>
export default {
  props: {
    btnText: {
      type: String,
      required: true
    },
    href: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '#9A7A2A'
    },
    withBorder: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.button {
  background-color: v-bind(backgroundColor);
  padding: 7px 10px;
  border: 1px solid v-bind(backgroundColor);
  border-radius: 3px;
  color: var(--white);
  font-size: 15px;
  position: relative;
  overflow: hidden;
  transition: color 1s;
  z-index: 1;
  text-decoration: none;
  text-align: center;
}

.button:hover {
  color: v-bind(backgroundColor);
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  width: 0;
  height: 100%;
  background-color: #ffffff;
  transform: skewX(35deg);
  z-index: -1;
  transition: width 0.8s;
}

.button:hover::before {
  width: 150%;
}
</style>
