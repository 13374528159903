<template>
  <div class="root-header">
    <div class="header px-3 py-2">
      <div class="icons">
        <a href="https://www.facebook.com/CloverLeafAWS" target="_blank"><FacebookSvg class="icon"/></a>
        <a href="https://instagram.com/cloverleafaws?igshid=YmMyMTA2M2Y=" target="_blank"><InstagramSvg class="icon"/></a>
        <a href="https://www.linkedin.com/company/cloverleaf-animal-welfare-systems" target="_blank"><LinkedinSvg class="icon" /></a>
        <a href="https://youtube.com/channel/UC2Y1jW07y68lzpM-VN0emUA" target="_blank"><YoutubeSvg class="icon" /></a>
      </div>

      <div class="user-actions">
        <!--<div class="login">
          <img class="icon" src="../assets/icons/user-login.svg" alt="">
          <p class="m-0">{{ $t('login') }}</p>
        </div>-->

        <LanguageSelector />

      </div>
    </div>

    <Navbar />
  </div>
</template>

<script>
import FacebookSvg from './Icons/FacebookSvg.vue'
import InstagramSvg from './Icons/InstagramSvg.vue'
import LinkedinSvg from './Icons/LinkedinSvg.vue'
import YoutubeSvg from './Icons/YoutubeSvg.vue'
import Navbar from './Navbar.vue'
import LanguageSelector from './LanguageSelector.vue'
import { onMounted } from 'vue'

export default {
  components: {
    FacebookSvg,
    InstagramSvg,
    LinkedinSvg,
    YoutubeSvg,
    Navbar,
    LanguageSelector
  },
  setup() {
    onMounted(() => {
      const header = document.querySelector('.root-header')
      const headerHeight = header.offsetHeight

      const scroll = () => {
        if (window.scrollY > headerHeight) {
          header.classList.add('fixed-top')
        } else {
          header.classList.remove('fixed-top')
        }
      }

      window.addEventListener('scroll', scroll)
    })
  }
}
</script>

<style scoped>
  .fixed-top {
    position: fixed;
    top: 0;
    transition: 0.6s;
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--primary-color);
    color: var(--white);
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .icons a {
    color: var(--white);
    height: 25px;
  }

  .icon {
    width: 25px;
    height: 25px;
  }

  .user-actions {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .login {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .login p {
    font-family: var(--montserrat-semibold);
  }

  @keyframes fade-in-top {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

</style>
