<template>
  <div class="row px-4 gy-4 footer-container">
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center align-items-center flex-column">
      <Logo />
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-center">
      <Links />
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
      <Contact />
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
      <Sponsors />
    </div>
  </div>
</template>

<script>
import Logo from './Logo.vue'
import Links from './Links.vue'
import Contact from './Contact.vue'
import Sponsors from './Sponsors.vue'

export default {
  components: {
    Logo,
    Links,
    Contact,
    Sponsors,
  }
}
</script>

<style scoped>
  .footer-container {
    background-image: url(../../assets/images/background-footer.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: 'Montserrat Medium';
  }
</style>
