<template>
  <router-view></router-view>
</template>

<script>
import { onMounted } from 'vue';
import { useAppStore } from './stores/app';
import AOS from "aos";

export default {
  setup() {

    const { setLanguage } = useAppStore();

    onMounted(() => {
      const language = localStorage.getItem('language') || 'en'
      setLanguage(language)
      AOS.init({
        duration: 900,
        disable: 'mobile'
      });
    });
  },
}
</script>
