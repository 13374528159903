<template>
  <div class="container-button-fab">
    <div v-if="openOptions" class="slide-in-bottom buttons d-flex flex-column align-items-center gap-10 mb-3">
      <button :title="$t('Form.email')" @click="openForm()" class="button sub-button">
        <EmailIcon />
      </button>
      <a title="WhatsApp" href="https://wa.me/18166464005" target="_blank" class="button sub-button">
        <WhatsAppIcon />
      </a>
    </div>
    <button :title="$t('open-contact-options')" class="button button-fab" @click="onOpenOptions()">
      <div :class="{'icon-translate': openOptions, 'transition-actions': !openOptions}">
        <AddIcon />
      </div>
    </button>
  </div>
</template>

<script>
import WhatsAppIcon from '@/components/Icons/WhatsAppIcon'
import EmailIcon from '@/components/Icons/EmailIconSvg'
import AddIcon from '@/components/Icons/AddIcon'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from 'vue'

export default {
  components: {
    EmailIcon,
    AddIcon,
    WhatsAppIcon
  },
  setup() {

    const openOptions = ref(false)
    const onOpenOptions = () => {
      openOptions.value = !openOptions.value
    }

    const router = useRouter()
    const route = useRoute();
    const routeName = computed(() => route.name)

    const openForm = () => {
      if (routeName.value !== 'contact') {
        router.push({
          name: 'contact',
          hash: '#form-contact'
        })
      }
      onOpenOptions()
    }

    return {
      openOptions,
      onOpenOptions,
      openForm
    }
  }
}
</script>

<style scoped>
.slide-in-bottom {
  animation: slide-in-bottom 0.5s both;
}

.icon-translate {
  animation: rotate-center 0.6s ease-in-out both;
}

.transition-actions {
  transition: all 0.2s ease;
}

.container-button-fab {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
}

.button {
  border-radius: 50%;
  padding: 20px;
  background-color: #647157;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease;
}

.button:hover {
  background-color: var(--primary-color);
}

.button-fab {
  width: 70px;
  height: 70px;
}

.sub-button {
  width: 65px;
  height: 65px;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(225deg);
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
