<template>
  <div class="sponsors">
    <h3 class="font-montserrat-sb">{{ $t('footer.sponsors')}}</h3>
    <img src="../../assets/icons/icons-sponsors.svg" alt="">
    <div class="text-center">
      <p class="font-noto-b">{{ $t('footer.becoming-sponsors')}}</p>
      <ButtonHover :btn-text="$t('click-here')" @click="openForm()"></ButtonHover>
    </div>
    <p>{{ $t('footer.sponsors-p')}}</p>
  </div>
</template>

<script>
import ButtonHover from "@/components/ButtonHover.vue";
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'

export default {
  components: {
    ButtonHover
  },
  setup() {
    const router = useRouter()
    const route = useRoute();
    const routeName = computed(() => route.name)

    const openForm = () => {
      if (routeName.value !== 'contact') {
        router.push({
          name: 'contact',
          hash: '#form-contact'
        })
      } else {
        const el = document.querySelector('#form-contact');
        if (el) {
          el.scrollIntoView({behavior: 'smooth'});
        }
      }
    }

    return {
      openForm
    }
  }
}
</script>

<style scoped>
.sponsors {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px
}

.sponsors img,
.logo {
  width: 80%;
}

.sponsors p {
  font-size: 15px;
}
</style>
