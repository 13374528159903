import { defineStore } from 'pinia'

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    language: 'en',
  }),

  actions: {
    setLanguage(language) {
      this.language = language
    }
  },
})
