<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid px-3">
      <router-link class="navbar-brand m-0" to="/" exact>
        <img src="@/assets/icons/Logotipo-dorado.svg" alt="">
      </router-link>

      <button @click="openMobileMenu()" class="navbar-toggler" aria-label="Toggle navigation">
        <MenuIcon />
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-lg-0">
          <li class="nav-item">
            <router-link to="/" exact>{{ $t('navbar.home') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about-us" exact>{{ $t('navbar.about') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/services">{{ $t('navbar.services') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/pricing">{{ $t('navbar.pricing') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/find-us">{{ $t('navbar.find-us') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/training">{{ $t('navbar.training') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/contact">{{ $t('navbar.contact') }}</router-link>
          </li>

          <li class="nav-item position-relative d-none d-sm-none d-md-none d-lg-block">
            <Submenu
              name-menu="navbar.services"
              :items-menu="[
                { name: 'navbar.pricing', path: '/pricing' },
                { name: 'navbar.training', path: '/training' }
              ]"
            />
          </li>
          <li class="nav-item position-relative d-none d-sm-none d-md-none d-lg-block">
            <Submenu
              name-menu="navbar.contact"
              :items-menu="[
                { name: 'navbar.find-us', path: '/find-us' },
                { name: 'navbar.contact', path: '/contact' }
              ]"
            />
          </li>
        </ul>
      </div>

      <ButtonHover
        class="d-none d-sm-none d-md-none d-lg-block"
        backgroundColor="#9a7a2a"
        :btn-text="$t('button-get-certified')"
        @click="openForm()"
      />
    </div>
  </nav>
</template>

<script>
import ButtonHover from './ButtonHover.vue'
import MenuIcon from './Icons/MenuSvg.vue'
import Submenu from './Submenu.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'

export default {
  components: {
    ButtonHover,
    MenuIcon,
    Submenu
  },
  setup() {
    const router = useRouter()
    const route = useRoute();
    const routeName = computed(() => route.name)

    const openForm = () => {
      if (routeName.value !== 'contact') {
        router.push({
          name: 'contact',
          hash: '#form-contact'
        })
      }
    }

    const openMobileMenu = () => {
      const navbar = document.getElementById('navbarSupportedContent')
      navbar.classList.toggle('show')
    }

    return {
      routeName,
      openForm,
      openMobileMenu
    }
  }
}
</script>

<style>

  .navbar {
    background-color: var(--primary-color) !important;
    font-family: var(--montserrat-semibold);
    width: 100%;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-brand img {
    width: 10em;
  }

  .navbar-nav a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    position: relative;
  }

  .navbar-nav a {
    color: white !important;
  }

  nav li a.router-link-exact-active {
    border-bottom: 0.1em solid white
  }

  .navbar-nav a:not(.router-link-exact-active)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: white;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  .navbar-nav a:hover::after,
  .navbar-nav a:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }

  .navbar-nav {
    gap: 3em;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .navbar-nav .nav-item a {
    font-size: 1.1em;
  }

  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    .navbar-brand img {
      width: 8em;
    }
    .navbar-nav {
      gap: 2em;
    }
    .navbar-nav .nav-item a {
      font-size: 15px
    }
    .button {
      padding: 5px
    }
  }

  @media screen and (min-width: 300px) and (max-width: 425px) {
    .navbar-brand img {
      width: 8em;
    }
    .navbar-nav {
      gap: 1.5em;
      padding: 20px 0px;
    }
  }

</style>
