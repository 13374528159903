<template>
  <div @click="showSubmenu = !showSubmenu" class="d-flex align-items-center text-white cursor-pointer">
    <a class="me-2" href="#">{{ $t(nameMenu) }}</a>
    <ArrowDownSvg v-if="!showSubmenu" />
    <ArrowUpSvg v-else />
  </div>
  <div class="submenu" v-if="showSubmenu">
    <ul>
      <li v-for="(item, index) of itemsMenu" :key="index">
        <router-link :to="item.path">{{ $t(item.name) }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ArrowDownSvg from './Icons/ArrowDownSvg.vue'
import ArrowUpSvg from './Icons/ArrowUpSvg.vue'

export default defineComponent({
  props: {
    nameMenu: {
      type: String,
      required: true
    },
    itemsMenu: {
      type: Array,
      required: true
    }
  },
  components: {
    ArrowDownSvg,
    ArrowUpSvg
  },
  setup() {
    const showSubmenu = ref(false)

    return {
      showSubmenu
    }
  },
})
</script>

<style scoped>
.submenu {
  position: absolute;
  background-color: var(--primary-color);
  border-radius: 0 0 0.5em 0.5em;
  top: 35px;
  padding-bottom: 1em;
  z-index: 2;
}

.submenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding-inline: 2em;
}
</style>
