<template>
  <Header />
  <Banner :key="routeName" />
  <router-view></router-view>
  <Footer />
  <ButtonFab />
</template>

<script>
import Header from "../components/Header.vue";
import Footer from '../components/Footer/Footer.vue'
import ButtonFab from '../components/ButtonFab.vue'
import Banner from '../components/Banner.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  components: {
    Banner,
    Footer,
    ButtonFab,
    Header
  },
  setup() {
    const route = useRoute()
    const routeName = computed(() => route.name)
    return {
      routeName
    }
  }
}
</script>
