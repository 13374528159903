<template>
  <img class="mb-2 logo" src="../../assets/icons/Logotipo-dorado.svg" alt="">
  <div class="icons-social-media">
    <a href="https://www.facebook.com/CloverLeafAWS" target="_blank">
      <FacebookSvg class="icon"/>
    </a>
    <a href="https://instagram.com/cloverleafaws?igshid=YmMyMTA2M2Y=" target="_blank">
      <InstagramSvg class="icon"/>
    </a>
    <a href="https://www.linkedin.com/company/cloverleaf-animal-welfare-systems" target="_blank">
      <LinkedinSvg class="icon" />
    </a>
    <a href="https://youtube.com/channel/UC2Y1jW07y68lzpM-VN0emUA" target="_blank">
      <YoutubeSvg class="icon" />
    </a>
    <img class="icon" src="@/assets/icons/iso.svg" alt="">
  </div>
</template>

<script>
import FacebookSvg from '../Icons/FacebookSvg.vue'
import InstagramSvg from '../Icons/InstagramSvg.vue'
import LinkedinSvg from '../Icons/LinkedinSvg.vue'
import YoutubeSvg from '../Icons/YoutubeSvg.vue'

export default {
  components: {
    FacebookSvg,
    InstagramSvg,
    LinkedinSvg,
    YoutubeSvg
  }
}
</script>

<style scoped>
.logo {
	width: 80%;
}
.icons-social-media {
	margin-left: 10px;
}
.icons-social-media a {
  color: #ad841c;
  margin: 5px;
}
.icons-social-media .icon {
  width: 30px;
  height: 30px;
}
</style>
